.main-slider3{
    height: 100%;
	padding: 0 80px 80px 0;
	background-color: var(--primary);
	@include respond ('tab-port'){
		padding: 0;
	}
	&:after{
		content: "";
		height: 100%;
		width: calc(calc(100% - 1200px) / 2);
		background-color: #fff;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.banner-inner{
		.swiper-slide{
			display: flex;
			align-items: center;
			justify-content: center;
			height: calc(100vh - 148px);
			min-height:800px;
			background-position:center left;
			&:before{
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				background: #000;
				opacity: 0.7;
				height: 100%;
			}
			&:after{
				content:"";
				height:100%;
				width:100%;
				position:absolute;
				top:0;
				left:0;
				background-image:url('../images/main-slider/pic1.png');
			}
			@include respond ('laptop'){
				min-height:680px;
			}
			@include respond ('phone-land'){
				min-height:auto;
				height:auto;
				padding-bottom: 180px;
				padding-top: 30px;
			}
		}
	}
	.slider-thumbs-wraper{
		width: 800px;
		position: absolute;
		right: 100px;
		bottom: 130px;
		.swiper-slide{
			opacity: 0.5;
			&.swiper-slide-active{
				opacity: 1;
			}
		}
		@include respond ('tab-port'){
			width:400px;
			right: 20px;
			bottom: 40px;
		}
		@include respond ('phone-land'){
			bottom:50px;
		}
		@include respond ('phone'){
			bottom:50px;
			width:calc(100% - 30px);
			right:auto;
			left:15px;
		}
	}
	.slider-thumbs{
		display: flex;
		align-items: center;
		cursor: pointer;
		
		.dz-media{
			min-width: 120px;
			height: 90px;
			overflow: hidden;

			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			@include respond ('phone'){
				min-width:90px;
			}
		}
		.dz-info{
			padding-left: 20px;
			.title{
				color: #fff;
			}
			p{
				color: #fff;
				margin-bottom: 0;
				@include respond ('phone'){
					font-size:16px;
				}
			}
		}
	}
	.banner-inner{
	    z-index: 1;
		position: relative;
		
		.banner-content{
			z-index: 1;
			position: relative;
			padding-left: 55px;
			
			.title{
				font-size: 80px;
				line-height: 90px;
				color: #fff;
				margin-bottom: 25px;
				@include respond ('laptop'){
					font-size:62px;
					line-height:72px;
				}
				@include respond ('phone-land'){
					font-size:45px;
					line-height:55px;
				}
				@include respond ('phone'){
					font-size:38px;
					line-height:45px;
				}
			}
			p{
				color: #fff;
				font-size: 18px;
				margin-bottom: 50px;
				padding-right: 150px;
				@include respond ('phone-land'){
					padding-right: 0px;
					margin-bottom:30px;
				}
			}
			@include respond ('phone'){
				padding-left:15px;
				margin-bottom:30px;
				p{
					font-size:16px;
				}
			}
		}
	}
	.swiper-button{
		left: 0;
		bottom: 0;
		width: 100%;
		justify-content: space-between;
		
		.swiper-button-prev,
		.swiper-button-next{
			height: 85px;
			width: 85px;
			background-color: rgba(255,255,255,0.7);
			line-height: 105px;
			font-size: 36px;
			color: var(--primary);
			
			&:hover{
				background-color: #fff;
			}
		}
	}
}