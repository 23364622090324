.under-construct{
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
	display: flex;	
	background-color: #fff;
	overflow: hidden;
	padding-left: 0;
	
	@include respond('tab-port'){
		flex-wrap: wrap;
	}
	
	.made-by{
	    font-weight: 600;
		color: #fff;
		text-decoration: underline;
	}
	.dz-title{
		font-size: 60px;
		line-height: 1.2;
		color:#fff;
		margin-bottom: 20px;
		font-family: var(--font-family-title);
		letter-spacing: 10px;
		
		@include respond('laptop'){
			font-size: 50px;
			line-height: 60px;
		}
		@include respond('tab-land'){
			font-size: 40px;
			line-height: 50px;
		}
		@include respond('phone'){
			font-size: 28px;
			line-height: 35px;
			margin-bottom: 15px;
			letter-spacing: 3px;
		}
	}
	
	.dz-under-bx{
		padding-left: 80px;
		padding-right: 80px;
		flex: 0 0 50%;
		max-width:50%;
		align-items: center;
		display: flex;
		
		@include respond('laptop'){
			padding-left: 50px;
			padding-right: 50px;
		}
		@include respond('tab-port'){
			flex: 0 0 100%;
			max-width: 100%;
			align-items: center;
			display: flex;
			padding: 60px 20px;
			text-align: center;
			justify-content: center;
		}
		@include respond('phone'){
			padding: 20px 10px;	
		}
	}
	
	.logo{
		margin-bottom:20px;
		width:200px;
		padding:5px;
		svg,
		img{
			width:100%;
		}
		@include respond('tab-port'){
			margin-bottom:20px;
			width:180px;
			margin-left:auto;
			margin-right:auto;
		}
	}
	@include respond('tab-port'){
		.bottom-left {
			padding-bottom: 30px;
		}
		.center-md{
			min-height: calc(100vh - 100px);
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 60px 0;
		}
		.dz-under-bx{
			display: block;
			padding: 0;
		}
	}
	
	.copyright-text{
		color:#fff;
		margin: 0;
		font-size: 16px;
	}
	.dz-social-icon{ 
		margin-bottom:15px;
		li {
			display:inline-block;
			margin-right: 5px;
			a{
				margin-right: 0px;
				width: 40px;
				height: 40px;
				background: var(--primary);
				line-height: 40px;
				text-align: center;
				border-radius: var(--border-radius-lg);
				color: #fff;
				&:hover{
					background:var(--hover-primary);
				}
			}
		}
	}
	
	.center-md{
		@include respond('tab-port'){
			display: block;
		}
		@include respond('phone-land'){
			padding: 30px 0;
		}
	}
	
	.mining-worker{
		width:60%;
		height:100%;
		position:absolute;
		top:0;
		right:0;
		z-index:-1;
		@include respond('phone-land'){
			opacity:0;
		}
		img{
			width: 12%;
			position: absolute;
			&:nth-child(1) {
				right: 25%;
				top: 0%;	
				animation: dzMove1 8s linear infinite;
			}
			&:nth-child(2) {
				left: 25%;
				top: 35%;
				animation: dzMove1 7s linear infinite;
			}
			&:nth-child(3) {
				left: 0%;
				top: 25%;
				animation: dzMove1 9s linear infinite;
			}
			&:nth-child(4) {
				bottom: 18%;
				right: 5%;
				animation: dzMove1 8.5s linear infinite;
			}
			&:nth-child(5) {
				bottom: 2%;
				right: 35%;
				animation: dzMove1 8s linear infinite;
			}
			&:nth-child(6) {
				left: 20%;
				top: 5%;
				animation: dzMove1 9s linear infinite;
			}
		}
	}
	@include respond('tab-port'){
		.bottom-left{
			padding-bottom: 0;
		}
		.bottom-right{
			padding-bottom: 20px;
		}

	}
	.train-run{
		width: 80%;
		position: absolute;
		bottom: 10%;
		transform: rotate(-35deg) translate(20%, 0);
		right: 0;
		z-index: -1;
		@include respond('tab-port'){
			width: 150%;
			bottom: 0;
		}
		.main-train{
			-webkit-animation:30s scroll infinite linear;
			-moz-animation:30s scroll infinite linear;
			-o-animation:30s scroll infinite linear;
			-ms-animation:30s scroll infinite linear;
			animation:30s scroll infinite linear;
			background-repeat:repeat-x;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 1;
			background-size: cover;
		}
		.train{
			opacity:0;
		}
		.track{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
	}
}

@-webkit-keyframes scroll{
	100%{
		background-position: -3000px 0px;
	}
}

/* Position */
.posi-center,
.bottom-right,
.bottom-left,
.top-right,
.top-left {
    position: fixed;
    z-index: 2;
	display: block;
	@include respond('tab-port'){
		position: unset;
	}
}
.bottom-right {
    bottom: 60px;
    right: 60px;
}
.bottom-left {
    bottom: 60px;
    left: 60px;
}
.top-right {
    top: 60px;
    right: 60px;
}
.top-left {
    top: 60px;
    left: 60px;
}