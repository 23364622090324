[class*="play-btn"]{
	position: relative;
	display: block;
}
.play-btn1{
	height: 95px;
	width: 95px;
	line-height: 95px;
	text-align: center;
	border: 4px solid;
	border-color: inherit;
	font-size: 30px;
	box-sizing: content-box;
	border-radius: 50%;				
	@include respond ('phone'){
		height: 60px;
		width: 60px;
		line-height: 60px;
		font-size:20px;
	}
	&:after{
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
		border: 3px solid #fff;
		border-radius: 50%;			 
		animation: animationSignal;
		animation-iteration-count: infinite;
		animation-duration: 3s;
		-webkit-animation: animationSignal;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-duration: 3s;
		transform: scale(1.2);
		-moz-transform: scale(1.2);
		-webkit-transform: scale(1.2);
		-ms-transform: scale(1.2);
		-o-transform: scale(1.2);
		@include transitionSlow;
	}
	&:hover{
		color: var(--primary);
	}
}
.play-btn2{
	height: 100px;
    width: 100px;
    background-color: inherit;
    text-align: center;
    line-height: 100px;
    font-size: 28px;
	color: #fff;
	background-color: var(--primary);
	border-radius: 50%;				
	@include respond ('phone'){
		height: 60px;
		width: 60px;
		line-height: 60px;
		font-size:20px;
	}
	&:after{
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
		background-color: var(--primary);
		border-radius: 50%;			 
		animation: animationSignal1;
		animation-iteration-count: infinite;
		animation-duration: 3s;
		-webkit-animation: animationSignal1;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-duration: 3s;
		transform: scale(1);
		-moz-transform: scale(1);
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		@include transitionSlow;
	}
	&:hover{
		background-color: #fff;
	}
	&:focus{
		color:#fff;
		background:var(--primary);
	}
}
.play-btn3{
	width: 115px;
    height: 115px;
    background-color: var(--primary);
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-family: var(--font-family-title);
    line-height: 115px;
    font-size: 18px;
    font-weight: 500;
	&:after{
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
		border: 2px solid var(--primary);
		border-radius: inherit;
		animation: animationSignal1;
		animation-iteration-count: infinite;
		animation-duration: 3s;
		-webkit-animation: animationSignal1;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-duration: 3s;
		transform: scale(1);
		-moz-transform: scale(1);
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		@include transitionSlow;
	}
	&:hover{
		color: #fff;
	}
	&:focus{
		color:#fff;
	}
}
.play-btn4{
	width: 92px;
    height: 92px;
    line-height: 92px;
    background-color: #fff;
    color: var(--primary);
    border-radius: 50%;
    text-align: center;
	font-size: 20px;
    border: 3px solid;
	border-color: var(--primary);
 
	&:hover{
		color: #fff;
		background-color: var(--primary);
		border-color: #fff;
	}
}
.play-btn5{
	height: 100px;
    width: 100px;
    line-height: 100px;
    font-size: 28px;
    background-color: inherit;
    text-align: center;
	color: var(--primary);
	background-color: #fff;
	border-radius: 50%;
	
	&:after{
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
		border-radius: 50%;
		background-color: #fff;
		animation: animationSignal1;
		animation-iteration-count: infinite;
		animation-duration: 3s;
		-webkit-animation: animationSignal1;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-duration: 3s;
		transform: scale(1);
		-moz-transform: scale(1);
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		@include transitionSlow;
	}
	@include respond ('phone'){
		height: 60px;
		width: 60px;
		line-height: 60px;
		font-size: 20px;
	}
	&:hover{
		background-color: #fff;
	}
}

.play-btn6{
	height: 70px;
    width: 70px;
    background-color: inherit;
    text-align: center;
    line-height: 70px;
    font-size: 20px;
	color: #fff;
	background-color: var(--primary);
	border-radius: 50%;
	
	&:after{
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
		border-radius: 50%;
		background-color: #fff;
		animation: animationSignal1;
		animation-iteration-count: infinite;
		animation-duration: 3s;
		-webkit-animation: animationSignal1;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-duration: 3s;
		transform: scale(1);
		-moz-transform: scale(1);
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		@include transitionSlow;
	}
	&:hover{
		background-color: #fff;
	}
}


// play button animation
@keyframes animationSignal {
	0% {
		opacity: 0.8;
		transform: scale(1.2);
		-moz-transform: scale(1.2);
		-ms-transform: scale(1.2);
		-o-transform: scale(1.2);
		-webkit-transform: scale(1.2);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal{
	0% {
		transform: scale(1.2);
		-moz-transform: scale(1.2);
		-ms-transform: scale(1.2);
		-o-transform: scale(1.2);
		-webkit-transform: scale(1.2);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}