// dz-accordion
.accordion{
	
	&.dz-accordion1{
		.accordion-item{
			overflow: hidden;
			background: #fff;
			margin-bottom: 20px;
			border: 0;
		}
		.accordion-body{
			padding: 25px 30px;
			border-radius: 0px;
			border: 2px solid rgba(0,0,0,0.2);
			border-width: 0 2px 2px;
		}
		.accordion-collapse{
			border: 0;
		}
		.accordion-header{
			.accordion-button{
				font-size: 18px;
				border: 2px solid;
				border-color: var(--primary);
				border-radius: 0;
				padding: 20px 65px 20px 35px;
				@include transitionMedium;
				color: #fff;
				background-color: var(--primary);
				font-weight: bold;
					
				&:after{
					content: none;
				}
				
				.toggle-close{
					font-family: themify;
					display: block;
					position: absolute;
					right: 25px;
					top: 21px;
					text-align: center;
					font-size: 18px;
					@include transitionMedium;
					z-index: 1;
					overflow: hidden;
					height: 20px;
					width: 20px;
					transform: rotate(90deg);
					
					&:before{
						content: "\f054";
						color: var(--title);
						font-size: 16px;
						font-family: "Font Awesome 5 Free";
						z-index: 99;
						opacity: 1;
						@include transitionMedium;
						display:block;
						color: #fff;	
						font-weight: bold;						
					}
				}
				
				&.collapsed{
					background: $white;
					color: inherit;	
					border-color: rgba(0,0,0,0.2);
					
					
					&:after{
						opacity:0;
					}
					.toggle-close{
						transform: rotate(0);
						&:before{
							opacity: 1;
							color: var(--title);
							transform: rotate(0);
						}
					}
					
				}
			}
			@include respond('phone'){
				.accordion-button{
					padding: 15px 65px 15px 15px;
					font-size:16px;
				}
			}
		}
	}
	&.dz-accordion2{
		.accordion-item{
			box-shadow: 1px 0px 60px 0 rgba(0, 0, 0, 0.08);
			border-radius: var(--border-radius-base);
			overflow: hidden;
			background: #fff;
			margin-bottom: 20px;
		}
		.accordion-body{
			padding: 25px 30px;
			border-radius: 0;
		}
		.accordion-collapse{
			border: 0;
		}
		.accordion-header{
			.accordion-button{
				font-size: 18px;
				border: 0;
				border-radius: 4px 4px 0 0;
				padding: 22px 65px 22px 35px;
				transition: all 0.5s;
				color: $white;
				box-shadow: 5px 0 15px var(--rgba-primary-4);
				background-color: var(--primary);
				font-weight: bold;
				&:after{
					content: none;
				}
				
				.toggle-close{
					background-color: $white;
					font-family: themify;
					height: 30px;
					width: 30px;
					display: block;
					position: absolute;
					right: 25px;
					top: 22px;
					border-radius: 30px;
					line-height: 25px;
					text-align: center;
					font-size: 16px;
					@include transitionMedium;
					z-index:1;
					overflow:hidden;
					transform: rotate(90deg);
					
					&:after{
						content: "";
						position:absolute;
						z-index:-1;
						background-color:var(--primary);
						@include transitionMedium;
						width:100%;
						height:100%;
						left:0;
						top:0;
						opacity:0;
					}
					
					&:before{
						content: "\f054";
						color: var(--primary);
						font-size: 14px;
						font-family: "Font Awesome 5 Free";
						z-index: 99;
						opacity: 1;
						transform: rotate(-90deg);
						@include transitionMedium;
						line-height: 28px;
						display:block;
						left: 48%;
						top: 50%;
						transform: translate(-50%, -50%);
						position: absolute;
						font-weight: bold;
					}
					@include respond('phone'){
						right: 20px;
					}
				}
				
				&.collapsed{
					background: $white;
					color: inherit;
					&:after{
						opacity:0;
					}
					.toggle-close{
						transform: rotate(0);
						
						&:after{
							opacity:1;
						}
						&:before{
							opacity: 1;
							color:$white;
						}
					}
					
				}
			}
			@include respond('phone'){
				.accordion-button{
					padding: 15px 65px 15px 15px;
					font-size:16px;
				}
			}
		}
	}
}