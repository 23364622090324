.nav-tabs{
	.nav-link{
		font-family:var(--font-family-title);
	}
	
	&.style-1{
		border:1px solid #dee2e6;
		border-radius:var(--border-radius-base);
		overflow:hidden;
		display: flex;
		flex-wrap: unset;
		
		.nav-item{
			border-right:1px solid #dee2e6;
			width: 100%;
			&:last-child{
				border-right:0;
			}
		}
		.nav-link{
			border-radius:0;
			border:0;
			color:#2b2a2a;
			font-size:15px;
			font-weight:600;
			text-transform:uppercase;
			padding:15px 15px;
			text-align: center;
			height:100%;
			display:flex;
			align-items:center;
			justify-content:center;
			&.active{
				background:var(--primary);
				color:#fff;
			}
		}
	}
	
	&.style-2{
		overflow:hidden;
		border: 0;
		
		.nav-link{
			border-radius:0;
			border:0;
			color:#2b2a2a;
			font-size:15px;
			font-weight:600;
			text-transform:uppercase;
			border-radius:var(--border-radius-base);
			padding:15px 30px;
			text-align: center;
			height:100%;
			display:flex;
			align-items:center;
			justify-content:center;
			&.active{
				background:var(--primary);
				color:#fff;
			}
		}
	}
	
	&.style-3{
		overflow:hidden;
		border: 1px solid #e9e9e9;
		background-color: #f1f1f1;
		border-radius: 4px;
		padding: 8px;
		
		.nav-link{
			border-radius:0;
			border:0;
			color:#2b2a2a;
			font-size:15px;
			font-weight:600;
			text-transform:uppercase;
			border-radius:var(--border-radius-base);
			padding:15px 30px;
			text-align: center;
			height:100%;
			display:flex;
			align-items:center;
			justify-content:center;
			&.active{
				background:var(--primary);
				color:#fff;
			}
		}
	}
}