[dir='rtl']{
    
    // Site Footer Style 1
    .site-footer{
        &.style-1{           
            &.widget_services{
                li{
                    a{
                        &:after{
                            left:auto;
                            right:0;
                        }
                    }
                }
            }
            .widget_about{
                padding-right: 0;
                padding-left: 10px;
            }
        }
    }

    .call-box2{
        padding:7px 55px 7px 10px;
        span{	
            left:auto;
            right:0;
            &:before{
                left: auto;
                right: 0;
            }
            &:after{
                left: auto;
                right: 0;
            }
        }
    } 
    
    // Site Footer 2
    
    .site-footer{
        &.style-2{
            .footer-info{
                .info-right{
                    padding-right:0;
                    padding-left:30px;
                    @include respond ('tab-port'){
                        padding-left:0;
                        padding-right:30px;
                    }
                    @include respond ('phone'){
                        padding-left:30px;
                        padding-right:30px;
                    }
                }
                .service-info{
                    li{
                        border-right:0;
                        border-left:2px solid rgba(255,255,255,0.2);
                        &:last-child{
                            border-left:0;
                            padding-left: 0;
                            padding-right: 20px;
                        }
                        &:first-child{
                            padding-right: 0;
                            padding-left: 20px;
                        }
                        @include respond ('phone'){
                            border-left:0;
							&:last-child{
								padding-right: 0;
							}
                        }
                    }
                }
            }
            .dzSubscribe{
                .ft-row{
                    .form-control{
                        padding-right:20px;
                        padding-left:50px;
                    }
                    .btn{
                        right:auto;
                        left:0;
						
						i.fa-arrow-right:before{
							content: "\f060";
						}
                    }
                }
            }
        }
    }
    
    //Footer 3
    // ft-clients
    
    .ft-clients-area{
        &:before{
            left: auto;
            right: 0;
        }
    }
    .site-footer{
        &.style-3{
            .footer-top{
                &:after{
                    left:auto;
                    right:100px;
                }
                &:before{
                    right:auto;
                    left:100px;
                }
            }
            &:after{
                left: auto;
                right: -200px;
            }
            &:before{
                right: auto;
                left: -200px;
            }
        }
    }
    
    //Site Footer 4
    
    .site-footer{
        &.style-4{
            .input-group{
                .input-group-addon{
                    right: auto;
                    left: 5px;
                }
            }
            .ft-subscribe{
                .ft-row{
                    .form-control{
                        margin-right: 0;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    
    //Site Footer 5
    
    .site-footer{
        &.style-5{
            .ft-subscribe{
                .ft-row{
                    .form-control{
                        margin-right: 0;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    
    //Default scss
    footer{        
        .widget-logo ul{
            li{
                padding: 0 0 15px 15px;	
            }
        }
        .footer-title{
            .dz-separator{
                left:auto;
                right:0;
            }
        }
        .widget-link{
            li{
                margin-left: 0;
                margin-right: 20px;
                @include respond('phone-land'){
                    margin-right: 8px;
                    margin-left: 7px;
                }
            }
        }
        .widget_services{
            &.style-1{
                ul{
                    li{
                        a{
                            padding: 5px 15px 5px 0;
                            &:before{
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .widget_getintuch{
        ul{
            li{
                padding-left: 0;
                padding-right: 35px;
                @include respond('tab-land'){	
                    padding-left: 0;
                    padding-right: 50px;
                }
                i{
                    left: auto;
                    right: 0;
                }
            }
        }
    }

    // footer-link
    .footer-link{
        li{
            padding: 0 20px 0 25px;
            &:before{
                left: auto;
                right: 0;
            }
        }
    }

    // widget_time
    .widget_time{
        ul{
            li{
                label{
                    margin-right: 0;
                    margin-left: 3px;
                }
            }
        }
    }
    // Support and Buy Button
    .DZ-theme-btn,
    .theme-btn{
        left: auto;
        right: 10px;
        &:focus,
        &:active,
        &:hover{
            span{
                padding-left:0;
                padding-right:10px;
            }
        }
    }
    .at-expanding-share-button[data-position=bottom-left]{
        right: 10px;
        left: auto;
    }
}