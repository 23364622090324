.dz-box.style-4{
	position:relative;
	transition:all 0.3s;
	.dz-media{
		display: block;
		overflow: hidden;
		position: relative;
		z-index:1;
		transition: transform 0.5s;
		border-radius: var(--border-radius-base);
		img{
			transition: transform 0.5s;
			border-radius: var(--border-radius-base);
			width: 100%;
			height:100%;
			object-fit:cover;
		}
		&:after{
			content:"";
			position:absolute;
			height:100%;
			width:100%;
			left:0;
			top:0;
			background:rgba(0,0,0,.3);
			opacity: 0;
			transition: opacity 0.6s;
			z-index:0;
		}
	}
	.view-btn{
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		transform:translate(-50%,-50%);
		opacity: 0;
		transition: opacity 0.6s;
		height:72px;
		width:72px;
		line-height:72px;
		text-align:center;
		background:var(--primary);
		color:#fff;
		font-size:28px;
	}
	&:hover{
		transform:scale(0.93, 0.93);
		.view-btn{
			opacity:1;
		}
		.dz-media{
			img{
				transform:scale(1.17, 1.17);
			}
			&:after{
				opacity:1;
			}
		}
	}
}