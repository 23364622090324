[data-theme-color="color_1"]{
	--gradient: linear-gradient( to right, rgb(255,64,62) 0%, rgb(255,129,27) 100%);
	--gradient-sec: linear-gradient( to right, rgb(57,26,166) 0%, rgb(6,163,245) 100%);
	--primary: #fe4c1c;
	--secondary: #192436;
	--primary-hover: #ec471b;
	--primary-dark: #ea4619;
	--rgba-primary-1: rgba(254,76,28,0.1);
	--rgba-primary-2: rgba(254,76,28,0.2);
	--rgba-primary-3: rgba(254,76,28,0.3);
	--rgba-primary-4: rgba(254,76,28,0.4);
	--rgba-primary-5: rgba(254,76,28,0.5);
	--rgba-primary-6: rgba(254,76,28,0.6);
	--rgba-primary-7: rgba(254,76,28,0.7);
	--rgba-primary-8: rgba(254,76,28,0.8);
	--rgba-primary-9: rgba(254,76,28,0.9);
	--title: #071c35;
	
}
[data-theme-color="color_2"]{
	--primary: #2eb999;
	--secondary: #192436;
	--primary-hover: #27ad8e;
	--primary-dark: #d48828;
	--rgba-primary-1: rgba(32,40,113,0.1);
	--rgba-primary-2: rgba(32,40,113,0.2);
	--rgba-primary-3: rgba(32,40,113,0.3);
	--rgba-primary-4: rgba(32,40,113,0.4);
	--rgba-primary-5: rgba(32,40,113,0.5);
	--rgba-primary-6: rgba(32,40,113,0.6);
	--rgba-primary-7: rgba(32,40,113,0.7);
	--rgba-primary-8: rgba(32,40,113,0.8);
	--rgba-primary-9: rgba(32,40,113,0.9);
	--title: #212529;
	--quote: url(../images/quote-2.png); 
}
[data-theme-color="color_3"]{
	--primary: #26AA5D;
	--secondary: #192436;
	--primary-hover: #00c75d;
	--primary-dark: #01a74f;
	--rgba-primary-1: rgba(0,214,100,0.1);
	--rgba-primary-2: rgba(0,214,100,0.2);
	--rgba-primary-3: rgba(0,214,100,0.3);
	--rgba-primary-4: rgba(0,214,100,0.4);
	--rgba-primary-5: rgba(0,214,100,0.5);
	--rgba-primary-6: rgba(0,214,100,0.6);
	--rgba-primary-7: rgba(0,214,100,0.7);
	--rgba-primary-8: rgba(0,214,100,0.8);
	--rgba-primary-9: rgba(0,214,100,0.9);
	--title: #212529;
	--quote: url(../images/quote-3.png); 
}

[data-theme-color="color_4"]{
	--primary: #e5588d;
	--secondary: #192436;
	--primary-hover: #da5b8b;
	--primary-dark: #d45987;
	--rgba-primary-1: rgba(229,88,141,0.1);
	--rgba-primary-2: rgba(229,88,141,0.2);
	--rgba-primary-3: rgba(229,88,141,0.3);
	--rgba-primary-4: rgba(229,88,141,0.4);
	--rgba-primary-5: rgba(229,88,141,0.5);
	--rgba-primary-6: rgba(229,88,141,0.6);
	--rgba-primary-7: rgba(229,88,141,0.7);
	--rgba-primary-8: rgba(229,88,141,0.8);
	--rgba-primary-9: rgba(229,88,141,0.9);
	--title: #212529;
	--quote: url(../images/quote-4.png);
}
[data-theme-color="color_5"]{
	--primary: #FB751B;
	--secondary: #192436;
	--primary-hover: #e36917;
	--primary-dark: #c95d15;
	--rgba-primary-1: rgba(251,117,27,0.1);
	--rgba-primary-2: rgba(251,117,27,0.2);
	--rgba-primary-3: rgba(251,117,27,0.3);
	--rgba-primary-4: rgba(251,117,27,0.4);
	--rgba-primary-5: rgba(251,117,27,0.5);
	--rgba-primary-6: rgba(251,117,27,0.6);
	--rgba-primary-7: rgba(251,117,27,0.7);
	--rgba-primary-8: rgba(251,117,27,0.8);
	--rgba-primary-9: rgba(251,117,27,0.9);
	--title: #071c35;
	--quote: url(../images/quote-5.png);
}
[data-theme-color="color_6"]{
	--primary: #e4484e;
	--secondary: #192436;
	--primary-hover: #d72c33;
	--primary-dark: #c92027;
	--rgba-primary-1: rgba(228,72,78,0.1);
	--rgba-primary-2: rgba(228,72,78,0.2);
	--rgba-primary-3: rgba(228,72,78,0.3);
	--rgba-primary-4: rgba(228,72,78,0.4);
	--rgba-primary-5: rgba(228,72,78,0.5);
	--rgba-primary-6: rgba(228,72,78,0.6);
	--rgba-primary-7: rgba(228,72,78,0.7);
	--rgba-primary-8: rgba(228,72,78,0.8);
	--rgba-primary-9: rgba(228,72,78,0.9);
	--title: #071c35;
	--quote: url(../images/quote-6.png);
}
[data-theme-color="color_7"]{
	--primary: #977fff;
	--secondary: #192436;
	--primary-hover: #5739db;
	--primary-dark: #391faf;
	--rgba-primary-1: rgba(151,127,255,0.1);
	--rgba-primary-2: rgba(151,127,255,0.2);
	--rgba-primary-3: rgba(151,127,255,0.3);
	--rgba-primary-4: rgba(151,127,255,0.4);
	--rgba-primary-5: rgba(151,127,255,0.5);
	--rgba-primary-6: rgba(151,127,255,0.6);
	--rgba-primary-7: rgba(151,127,255,0.7);
	--rgba-primary-8: rgba(151,127,255,0.8);
	--rgba-primary-9: rgba(151,127,255,0.9);
	--title: #212529;
	--quote: url(../images/quote-7.png);
}
[data-theme-color="color_8"]{
	--primary: #eb8153;
	--secondary: #192436;
	--primary-hover: #cf6233;
	--primary-dark: #b54f23;
	--rgba-primary-1: rgba(235,129,83,0.1);
	--rgba-primary-2: rgba(235,129,83,0.2);
	--rgba-primary-3: rgba(235,129,83,0.3);
	--rgba-primary-4: rgba(235,129,83,0.4);
	--rgba-primary-5: rgba(235,129,83,0.5);
	--rgba-primary-6: rgba(235,129,83,0.6);
	--rgba-primary-7: rgba(235,129,83,0.7);
	--rgba-primary-8: rgba(235,129,83,0.8);
	--rgba-primary-9: rgba(235,129,83,0.9);	
	--title: #212529;
	--quote: url(../images/quote-8.png);
}
[data-theme-color="color_9"]{
	--primary: #ff8f16;
	--secondary: #192436;
	--primary-hover: #e37f13;
	--primary-dark: #cb700e;
	--rgba-primary-1: rgba(255,143,22,0.1);
	--rgba-primary-2: rgba(255,143,22,0.2);
	--rgba-primary-3: rgba(255,143,22,0.3);
	--rgba-primary-4: rgba(255,143,22,0.4);
	--rgba-primary-5: rgba(255,143,22,0.5);
	--rgba-primary-6: rgba(255,143,22,0.6);
	--rgba-primary-7: rgba(255,143,22,0.7);
	--rgba-primary-8: rgba(255,143,22,0.8);
	--rgba-primary-9: rgba(255,143,22,0.9);
	--title: #212529;
	--quote: url(../images/quote-9.png);
}