#loading-area {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999999;
	background-position:center;
	background-repeat:no-repeat;
	background-size:80px;
    overflow: hidden;
	
	&.loading-page-1{
		display:flex;
		align-items:center;
		justify-content: center;
		.bar-load{
			width:100%;
		}
		&:before,
		&:after{
			content: "";
			position: absolute;
			width: 100%;
			height: 50%;
			left: 0;
			top: 0;
			background: #192436;
			transition: all 1.5s;
			transform-origin: left top;
			z-index: -1;		
		}
		&:before{
			top:auto;
			bottom:0;
			
		}
		&.active:before,
		&.active:after{
			height:0px;
		}
		.line{
			width:100%;
			height:3px;
			position:relative;
			background-color:rgba(255,255,255,0.05);
			overflow:hidden;
			&:after{
				position:absolute;
				top:0;
				left:0;
				height:100%;
				width:100px;
				content:"";
				background-color:var(--primary);
				animation-name: center-animate;
				animation-duration: 3s;  
				animation-fill-mode: forwards;
				
			}
		}

	}
	
	&.loading-page-2{
		background-color: #071c35;
		.loading-inner{
			font-family: var(--font-family-title);
			position:absolute;
			top:50%;
			left:50%;
			width:150px;
			height:150px;
			border:3px solid #ffffff;
			transform:translate(-50%,-50%);
			background:transparent;
			border-radius:50%;
			text-align:center;
			font-size:20px;
			line-height:150px;
			color:var(--primary);
			text-transform:uppercase;
			box-shadow:0 0 20px rgba(0,0,0,.5);
			
			&:before{
				content:'';
				position:absolute;
				top:-3px;
				left:-3px;
				border:3px solid transparent;
				height:100%;
				width:100%;
				border-top:3px solid var(--primary);
				border-radius:50%;
				border-right:3px solid var(--primary);
				animation: dzLoad1 2s linear infinite;
			}
			.loading{
				display:block;
				position:absolute;
				left:50%;
				width:50%;
				top:calc(50% - 2px);
				height:4px;
				transform-origin:left;
				background:transparent;
				animation:dzLoad2 2s linear infinite;
				&:before{
					content:"";
					position:absolute;
					background: var(--primary);
					width:16px;
					box-shadow:0 0 20px var(--primary);
					height:16px;
					border-radius:50%;
					top:-6px;
					right:-8px;
				}
			}
		}
		.load-text .text-load{
			font-weight: 900;
			display: inline-block;
			color: rgba(255, 255, 255, 0.1);
			position: relative;
			font-size: 70px;
			line-height: 70px;
			font-size: 24px; 
			text-align: center;
			user-select: none;
			text-transform: uppercase;
			font-family: var(--font-family-title);
			letter-spacing: -1px;
				
			&:before {
				animation: letters-loading 4s infinite;
				color: #fff;
				content: attr(data-text);
				left: 0;
				opacity: 0;
				top:0;
				line-height: 70px;
				position: absolute;
				text-transform: uppercase;
			}
			&:nth-child(2):before {animation-delay: 0.2s;}
			&:nth-child(3):before {animation-delay: 0.4s;}
			&:nth-child(4):before {animation-delay: 0.6s;}
			&:nth-child(5):before {animation-delay: 0.8s;}
			&:nth-child(6):before {animation-delay: 1s;}
			&:nth-child(7):before {animation-delay: 1.2s;}
			&:nth-child(8):before {animation-delay: 1.4s;}
		}
	}
	
	&.loading-page-3{
		background-color: #071c35;
		
		.loading-inner{
			width: 160px;
			height: 160px;
			margin: 0 auto;
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			
			.loader{
				background-color: rgba(0,0,0,0.04);
				border: 4px solid rgba(255,255,255,0.1);
				border-left: 4px solid var(--primary);
				border-top: 4px solid var(--primary);
				border-radius: 50%;
				box-shadow: 0 0 15px rgba(0,0,0,0.03);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				animation: dzLoad3 3s linear infinite;
				
				.circle{
					transform-origin: left;
					position: absolute;
					top: calc(50% - 1px);
					left: 50%;
					width: 50%;
					height: 2px;
					
					&:before{
						content: '';
						background: #fff;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						position: absolute;
						top: -57px;
						right: 14px;
						background: var(--primary);
						box-shadow: 0 0 20px var(--primary), 0 0 40px var(--primary), 0 0 60px var(--primary), 0 0 80px var(--primary), 0 0 100px var(--primary), 0 0 0 5px rgba(255, 255, 0, .1);
					}
				}
				&:nth-of-type(2){
					background-color: rgba(0, 0, 0, 0.025);
					border: 4px solid rgba(255,255,255,0.1);
					border-right: 4px solid #5aaf1e;
					border-bottom: 4px solid #5aaf1e;
					top: 30px;
					left: 30px;
					right: 30px;
					bottom: 30px;
					animation: dzLoad3 3s reverse linear infinite;
					
					.circle{
						transform: rotate(-45deg);
						
						&:before{
							top: -4px;
							right: -6px;
							background: #5aaf1e;
							box-shadow: 0 0 20px #5aaf1e, 0 0 40px #5aaf1e, 0 0 60px #5aaf1e, 0 0 80px #5aaf1e, 0 0 100px #5aaf1e, 0 0 0 5px rgba(3, 169, 244, .1);
						}
					}
				}
			}
		}
	}
	
	&.loading-page-4{
		background-color: #fff;
		
		.loading-inner{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			
			.wrapper{
				margin: auto;
				position: relative;
				height: 100px;
				width: 100px;
				overflow: hidden;
				svg,
				img{
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width:100%;
				}
				.img1{
					-webkit-animation: rotate360 5s linear infinite;
					animation: rotate360 5s linear infinite;
				}
			}
			.load-text .text-load{
				font-weight: 900;
				letter-spacing: 15px;
				display: inline-block;
				color: rgba(0, 0, 0, 0.15);
				position: relative;
				font-size: 70px;
				line-height: 70px;
				font-size: 60px; 
				letter-spacing: 1px;
				text-align: center;
				user-select: none;
				text-transform: uppercase;
				font-family: var(--font-family-title);
				&:before {
					animation: letters-loading 4s infinite;
					color: var(--title);
					content: attr(data-text);
					left: 0;
					opacity: 0;
					top:0;
					line-height: 70px;
					position: absolute;
					text-transform: uppercase;
				}
				&:nth-child(2):before {animation-delay: 0.2s;}
				&:nth-child(3):before {animation-delay: 0.4s;}
				&:nth-child(4):before {animation-delay: 0.6s;}
				&:nth-child(5):before {animation-delay: 0.8s;}
				&:nth-child(6):before {animation-delay: 1s;}
				&:nth-child(7):before {animation-delay: 1.2s;}
				&:nth-child(8):before {animation-delay: 1.4s;}
			}
			@include respond('phone'){
				.load-text .text-load{
					font-size: 30px;
				}
				.wrapper{
					height: 80px;
					width: 80px;
				}
			}
		}
	}
	
	&.loading-page-5{
		background-color: #fff;
		
		.loading-inner{
			text-align: center;
			position: absolute;
			line-height: 50px;
			text-transform: uppercase;
			width: 250px;
			height: 50px;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-family: var(--font-family-title);
			color: var(--title);
			letter-spacing: 5px;
			font-weight: 900;

			&::before,
			&::after {
				content: "";
				display: block;
				position: absolute;
				width: 15px;
				height: 15px;
				animation: dzLoad4 .7s infinite alternate ease-in-out;
				background-color: var(--primary);
			}  
			&::before{
				top: 0;
			}  
			&::after {
				bottom: 0;
			}
		}
	}
}


@keyframes dzLoad4 {
	0% {
		left: 0;
		height: 30px;
		width: 15px;
	}
	50% {
		height: 8px;
		width: 40px;
	}
	100% {
		left: 235px;
		height: 30px;
		width: 15px;
	}
}
@keyframes center-animate {
	from {
		width: 0;
	}
	to {
		
		width: 100%;
	}
}

@keyframes dzLoad1{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}
@keyframes dzLoad2{
	0%{
		transform:rotate(45deg);
	}
	100%{
		transform:rotate(405deg);
	}
}

@keyframes dzLoad3{
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}
@keyframes rotation {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes letters-loading{
	0%,
	75%,
	100% {
		opacity: 0;
		transform: rotateY(-90deg);
	}
	25%,
	50% {
		opacity: 1;
		transform: rotateY(0deg);
	}
}
@keyframes rotate360{
	0%{
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100%{
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	} 
}