[dir='rtl']{
	
	// Header Nav
	.header-nav {
		.nav{
			&>li {
				&.sub-menu-down,
				&.has-mega-menu{
					&>a {
						&:after{
							@include respond('tab-port'){
								content:"\f053";
							}
						}
					}
					@include respond('tab-port'){
						&.open{
							&>a {
								&:after{
									content:"\f078";
								}
							}
						}
					}
				}
				
				.mega-menu,
				.sub-menu {
					li{
						a {
							@include respond('tab-port'){
								&:after{
									content: "\f053";
								}
							}
						}
					}
				}
				.sub-menu{
					&.open{
						& > a > i:before{
							content:"\f078";
						}
					}
					& > li.open > a i:before{
						content: "\f107";
					}				
				}
			}
		}
	}
	
	// Header-1
    .site-header{
        &.style-1{
            &:before{
                left: auto;
                right: calc(calc(100% - 1140px) / 2 + 280px);
                transform: skewX(-20deg);
                @include custommq ($max:1199px){
                    left: auto;
                    right: calc(calc(100% - 960px) / 2 + 280px);
                }
                @include respond ('tab-port'){
                    left: auto;
                    right: calc(calc(100% - 720px) / 2 + 280px);
                }
                @include respond ('phone-land'){
                    left: auto;
                    right: calc(calc(100% - 540px) / 2 + 280px);
                }
                @include respond ('phone'){
                    left: auto;
                    right: 210px;
                }
            }
            &:after{
                right: auto;
                left: 0;
            }
            
            .main-bar-wraper{
                &:after{
                    left: auto;
                    right: -40px;
                    transform: skewX(-20deg);
                }
            }
            .top-bar {
                .dz-topbar-inner{
                    margin-left:0;
                    margin-right:calc(calc(100% - 1140px) / 2 + 295px);
                    @include custommq ($max:1199px){
                        margin-right:0;
                    }
                }
            }
            
            .main-bar{
                &:before{
                    transform: skew(-20deg);
                    border-left: 0;
                    border-right: 3px solid var(--primary);
                    left: auto;
                    right: calc(calc(100% - 1140px) / 2 + 220px);
                }
                &:after{
                    right: auto;
                    left: 0;
                }
                @include custommq ($max:1199px){
                    &:before{
                        left: auto;
                        right: calc(calc(100% - 960px) / 2 + 220px);
                    }
                }
                @include respond ('tab-port'){
                    &:before{
                        left: auto;
                        right: calc(calc(100% - 720px) / 2 + 220px);
                    }
                }
                @include respond ('phone-land'){
                    &:before{
                        left: auto;
                        right: calc(calc(100% - 540px) / 2 + 220px);
                    }
                }
                @include respond ('phone'){
                    &:before{
                        left: auto;
                        right: 175px;
                    }
                }
            }
            // Header Nav
            @include respond ('tab-port-min'){
                .header-nav {
                    padding-left: 0;
                    padding-right: 130px;
                    @include custommq ($max:1199px){
                        padding-left: 0;
                        padding-right: 60px;
                    }
                }
            }
        }
    }
    
    
    // Header-2
    // site-header
    .site-header{
        &.style-2{
            .is-fixed{
                @include respond ('tab-port'){
                    .navbar-toggler{
                        margin:15px 15px 15px 0;
                    }
                }
            }
            @include respond ('tab-port'){
                .navbar-toggler{
                    margin:30px 15px 30px 0;
                }
            }
            @include respond ('phone'){
                .navbar-toggler{
                    margin:15px 15px 15px 0;
                }
            }
            .extra-nav{
                padding-left:0;
                padding-right:50px;
                @include custommq ($max:1200px){
                    padding-left:0;
                    padding-right:30px;
                }
                @include respond ('phone'){
                    padding-right:0;
                }
            }
            
            .header-nav{
                .nav{
                    @include respond ('tab-port-min'){
                        & > li{
                            & > a{
                                &:before{
                                    left:auto;
                                    right:50%;
                                    margin-right:0;
                                    transform:translateX(50%);                                    
                                }
                            }
                        }
                    }
                }
            }
            
            .menu-btn{
                margin-left:0;
                margin-right:30px;
                span{
                    left: auto;
                    right: 18px;
                }
                @include respond ('laptop'){
                    span{
                        left:auto;
                        right:12px;
                    }
                }
                @include custommq ($max:1200px){
                    margin-left:auto;
                    margin-right:30px;
                }
                
                &.open{
                    span{
                        &:nth-child(1) {
                            transform: rotate(-45deg);
                        }
                        &:nth-child(3) {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            
        }
    }
    
    
    // Header-3
    .site-header.style-3{
        .logo-header{
            margin-right:0;
            margin-left:60px;
            @include respond ('laptop'){
                margin-right:auto;			
                margin-left:40px;			
            }
            @include respond ('phone'){
                margin-left:0;
            }
        }
        .extra-nav{
            padding-left:80px;
            margin-right:0;
            padding-right:0;
            @include custommq ($max:1200px){
                padding-left:0;
            }
            @include respond ('tab-port'){
                padding-left:10px;
            }
        } 
        .top-bar{
            .help-text{
                strong{
                    padding-left: 10px;
                }
            }
        }
        
    }

    .search-inhead {
        margin-left: 0;
        margin-right: 30px;
        padding-left: 0;
        padding-right: 20px;
        border-right:0;
        border-left:1px solid #d7d7d7;
        margin-right:0;
        margin-left:30px;
        padding-left:30px;
        padding-right:0;
        @include custommq ($min : 1280px){
            #quik-search-btn {
                right: auto;
                left: 30px;
                transform:translateY(-50%);
            }
        }
        @include custommq ($max:1200px){
            margin-left:0;
            padding-right:20px;
            padding-left:20px;
            border-left:0;
        }
        @include respond ('phone'){
            padding-left:0;
        }
    }
    
    
    // Header-4
    .site-header.style-4{
        .logo-header{
            padding-right:70px;
            padding-left:40px;
            @include respond ('tab-port'){
                padding-right:15px;
                padding-left:15px;
            }
        } 
        .search-inhead{
            margin-left:0;
            border-left:0;
            padding-right:25px;
            padding-left:0;
            @include custommq ($max:1200px){
                padding-right:0;
                padding-left:25px;
            }
            @include respond ('tab-port'){
                padding-right:0;
                padding-left:10px;
            }
        }
    }


    // Header-5
    .site-header.style-5{
        .top-bar{
            .dz-topbar-left{
                ul{
                    li{
                        border-right:0;
                        border-left:1px solid #e3e3e3;
                        margin-right: 0;
                        margin-left: 20px;
                        &:last-child{
                            border-left:0;
                        }	
                    }
                }
            }
            .dz-social{
                li{
                    border-left:0;
                    border-right:1px solid #e3e3e3;
                    &:last-child{
                        border-left:1px solid #e3e3e3;
                    }
                }
            }
        }
        .service-info{
            margin-right:0;
            margin-left:30px;
        }
    }
    
    //Header sidenav
    .site-header.header-sidenav-1{
        .header-nav {
            .nav{
                &>li {
                    .sub-menu li > .sub-menu{
                        right: auto;
                        left: 0;
                    }
                }
                .sub-menu {
                    right: auto;
                    left: 0;
                }
                .sub-menu-down > a{
                    @include custommq ($min:767px){
                        &:after{
                            right: auto;
                            left: 20px;
                            margin-right: 10px;
                            margin-left: 0;
                            transform: translateY(-50%);
                        }
                        &.dz-open:after{
                            transform: translateY(-50%) rotate(90deg);
                        }
                    }
                    
                }
                .sub-menu-down .sub-menu-down > a{
                    @include custommq ($max:767px){
                        &:before{
                            content:"\f078";
                            right: auto;
                            left: 0px;
                            float: left;
                        }
                        &.dz-open:before{
                            transform:rotate(90deg);
                        }
                    }
                }
            }
        }
        .nav-elements span{
            margin-right: 0;
            float: right;
            margin-left: 10px;
            margin-top: 5px;
        }
        
        .extra-nav{
            padding-left:0;
            padding-right:80px;
            @include custommq ($max:1200px){
                padding-left:0;			
                padding-right:15px;			
            }
        }
        .menu-btn{
            span{
                right: auto;
                left: 9px;
            }
            
            &.open{
                span{
                    &:nth-child(1) {
                        transform: rotate(-45deg);
                    }
                    &:nth-child(2) {
                        right:auto;
                        left:40px;
                    }
                    &:nth-child(3) {
                        transform: rotate(45deg);
                    }
                }
            }
            @include respond ('phone'){
                span{
                    right:auto;
                    left:10px;
                }
            }
        }
    }

    .service-list{
        margin-right: auto;
        margin-left: 0;
        ul{
            li {
                padding: 0 70px 0 0;
                margin-left: 0;
                margin-right: 30px;
                i{
                    left:auto;
                    right:0;
                }
            }
        }
        @include custommq ($max:1200px){
            ul{
                li{
                    margin-left: auto;
                    margin-right: 20p;
                    padding: 0px 52px 0px 0;
                }
            }
        }
    }

    .full-sidenav{
        left: auto;
        right: 0;
        .social-menu{
            li{
                margin-right: 0;
                margin-left: 10px;
            }
        }
        .service-list{
            ul{
                li{
                    margin-right:0;
                }
            }
        }
        @include respond ('tab-port-min'){
            .header-tabs-wraper{
                .header-style-content{
                    .menu-list{
                        &>li{
                            i{
                                margin-left:10px;
                            }
                        }
                    }
                }
            } 
        }
        
        @include respond ('tab-port'){
            left:auto;
            right:-300px;
            &.show{
                left:auto;
                right:0;
            }
        }
    }


    .flsbg {
        left: auto;
        right: 0;
        .bg-wrap { 
            span {
                left: auto;
                right: 0;
            }
        }
    }
    
    //Header-Default
    // Header Logo
    .logo-header {
        float: right;
    }

    // Top Bar
    .top-bar {
        .dz-topbar-left {
            float: right;
            li{
                padding-right: 0;
                padding-left: 20px;
            }
        }
        .dz-topbar-right {
            float: left;
            li{
                padding-left: 0;
                padding-right: 15px;
            }
        }
        .dz-topbar-center,
        .dz-topbar-left,
        .dz-topbar-right {
            ul{
                i{
                    margin-right: 0;
                    margin-left: 5px;
                }
            }
            .dz-social-icon{
                li{
                    padding-right: 0;
                }
            }
        }
    }

    // Quik Search
    .dz-quik-search{
        left: auto;
        right: 0;
        .form-control {
            padding: 15px 15px 15px 60px;
        }
        span{
            right:auto;
            left:15px;
        }
    }

    // Site Header
    .site-header {
        .extra-nav {
            float: left;
            padding-left: 0;
            padding-right: 30px;
            @include respond('wide-desktop'){	
                margin-left:0;
                margin-right:10px;
            }
            #quik-search-btn{
                margin-left: 0px;
                border-radius: 60px;
            }
        }
    }

    // Navbar Toggler
    .navbar-toggler {
        margin: 40px 15px 39px 0;
        float: left;
        @include respond('tab-port'){
            margin: 15px 15px 15px 0;
        }
        @include respond('phone'){
            margin: 15px ​15px 15px 0;
        }
    }

    // Navicon
    .navicon{
        &.open span:nth-child(1) {
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        &.open span:nth-child(2) {
            left: auto;
            right: -60px;
        }
        &.open span:nth-child(3) {
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
    }


    // Header Nav
    .header-nav {        
        @include respond('tab-port'){
            left:auto;
            right:-280px;
        }
        &.show {
            @include respond('tab-port'){
                left: auto;
                right: -1px;
                margin:0;
            }
        }
        .nav{
            &>li {
                &.sub-menu-down,
                &.has-mega-menu{
                    &>a {
                        &:after{
                            margin-left: 0;                                
                            margin-right: 5px;                                
							display: inline-block;
							
                            @include respond('tab-port'){
                                right: auto;
                                left: 0;
                                float: left;
                            }
                        }
                    }
                }
                .mega-menu,
                .sub-menu {
                    left: auto;
                    right: 0;
                    text-align: right;
                    @include respond('tab-port'){	
                        & > li > a i{
                            right: auto;						
                            left: 0;						
                        }
                    }
                    li{                        
                        a { 
                            @include respond('tab-port'){
                                padding: 2px 16px 1px 0;
                                &:after{
                                    left: auto;
                                    right: 0;
                                }
                            }
                        }
                        &:hover > a{
                            @include respond('tab-port'){
                                padding: 2px 16px 1px 0;
                            }
                        }
                    }
                    li>.sub-menu {
                        left: auto;
                        right: 220px;
                    }
                    @include respond('tab-port'){
                        .mega-menu,
                        .sub-menu{
                            padding-left: 0;
                            padding-right: 20px;
                        }
                    }
                }
                .sub-menu{
                    li > a > i {
                        float: left;	
                    }				
                }
                .mega-menu {
                    right: auto;
                    left: 0px;
                    &>li {
                        &>a {
                            &>i{
                                margin-right: 0;
                                margin-left: 5px;
                            }
                        }
                        li i{
                            margin-right: 0;
                            margin-left: 5px;
                        }
                        
                    }
                }
            }
            @include respond('laptop'){
                &>li:nth-last-child(3),
                &>li:nth-last-child(2),
                &>li:last-child{
                    .sub-menu .sub-menu{
                        left:auto;
                        right:-220px;
                    }
                }
            }
            @include custommq($max: 1199px) {
                &>li:last-child{
                    .sub-menu{
                        left:0;
                        right:auto;
                    }
                }
            }
        }
    }

    // Header Transparent{
    .header-transparent{
        left:auto;
        right:0;
        
    }

    // Is Fixed
    .is-fixed{
        .main-bar {
            left: auto;
            right: 0;
        }
    }

    // Mobile Nav
    @include respond('tab-port'){
        .mo-left{
            .header-nav{
                left:auto;
                right:-280px;
                &.show {
                    left: auto;
                    right: -1px;
                }
            }	
            .navbar-toggler{
                &.open{
                    &:after {
                        left: -20px;
                        right: 0;
                        transform-origin: top left;
                        margin: 0 20px 0px 0;
                    }
                }
            }
        }
    }
    
    // Header Tabs Wraper
    .header-tabs-wraper{
        .header-style-bx{
            .header-style-tabs{
                padding: 20px 20px 20px 0;                			
            } 
        }
        .header-style-content{
            .menu-list{
                text-align: right;                    
                & > li{    
                    & > ul{
                        & > li{
                            i{
                                margin-right:0;
                                margin-left: 5px;
								float: right;
                            }
                        }
                    }
                    &:last-child{
                        border-left: 0;
                    }
                    @include respond('tab-port'){
                        border-left: 0;
                    }
                }
            }
        }
    }
}