.dz-social-icon{
	ul{
		display: flex;
		li{
			display: inline-block;
			padding: 0 5px;
			a{
				display: block;
				width: 45px;
				height: 45px;
				background-color: var(--primary);
				color: #fff;
				text-align: center;
				line-height: 45px;
			}
		}
	}
}