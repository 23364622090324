.dz-box.style-6{
	.dz-media{
		display:block;
		background-size: cover;
	}
	&:after{
		content:"";
		position:absolute;
		height:100%;
		width:100%;
		top:0;
		left:0;
		background:var(--secondary);
		opacity:.4;
		@include transitionSlow;
	}
	.overlay-bx {
		opacity: 1;
		visibility: visible;
		.title,
		.title a{
			color:#fff;
		}
	}
	.info{
		position:absolute;
		bottom:0;
		left:0;
		text-align:left;
		padding: 30px 40px;
		@include transitionSlow;
	}
	.view-btn{
		width: 100px;
		height: 100px;
		display: block;
		font-size: 34px;
		line-height: 100px;
		text-align: center;
		background: var(--primary);
		border-radius: 100px;
		color:#fff;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -50px 0px 0px -50px;
		transform: scale(0) rotate(360deg);
		-moz-transform: scale(0) rotate(360deg);
		-webkit-transform: scale(0) rotate(360deg);
		-ms-transform: scale(0) rotate(360deg);
		-o-transform: scale(0) rotate(360deg);
		i{
			vertical-align: middle;
		}
	}
	&:hover{
		.view-btn{
			transform: scale(1) rotate(0deg);
			-moz-transform: scale(1) rotate(0deg);
			-webkit-transform: scale(1) rotate(0deg);
			-ms-transform: scale(1) rotate(0deg);
			-o-transform: scale(1) rotate(0deg);
		}
		&:after{
			opacity:.8;
		}
	}
}