.section-head{
	margin-bottom:40px;
	@include respond('tab-land'){
		margin-bottom:30px;
	}
	@include respond('phone-land'){
		margin-bottom:20px;
	}
	h1, h2, h3, h4, h5, h6{
		font-family: var(--font-family-title);
	}
	h2{
		font-size: 3.5rem;
		line-height: 1.2;
		@include respond('laptop'){
			font-size:2.8rem;
		}
		@include respond ('tab-port'){
			font-size:2.5rem;
		}
		@include respond ('phone-land'){
			font-size:2rem;
		}
	}
	h3{
		font-size:3rem;
		line-height:1.2;
		@include custommq ($max:1200px){
			font-size:2.3rem;
		}
		@include respond ('tab-port'){
			font-size:1.75rem;
		}
	}
	p{
		font-size: 18px;
		line-height: 1.7;
		@include respond ('laptop'){
			font-size: 16px;
		}
	}
	&.text-center{
		max-width:800px;
		margin-left:auto;
		margin-right:auto;
		/* p{
			max-width:550px;
			margin-left:auto;
			margin-right:auto;
		} */
	}
	.sub-title{
		text-transform:uppercase;
		margin-bottom: 5px;
	}
}