.pagination{
	li{
		margin: 0 8px;
		.page-numbers,
		.page-link{
			height: 60px;
			min-width: 60px;
			line-height: 60px;
			border-radius: 60px;
			color: #777777;
			text-align: center;
			border: 0;
			padding: 0;
			transition: all 0.5s;
			font-size: 18px;
			padding: 0 10px;
			display: block;
			background-color:#ebebeb;
			
			&.current,
			&.active,
			&:hover{
				color: $white;
				background-color: var(--primary);
				border-color: var(--primary);
				box-shadow: 0px 5px 12px var(--rgba-primary-4);
			}
		}
		@include respond ('phone'){
			margin: 0 5px;
			.page-numbers,
			.page-link{
				height: 40px;
				min-width: 40px;
				line-height: 40px;
				border-radius: 40px;
			}
		}
		.prev,
		.next{
			background-color:transparent;
			border-color: transparent;
			font-size: 18px;
			&:hover{
				background-color:transparent;
				box-shadow:none;
				color:var(--primary);
			}
		}
	}
	&.text-center{
		justify-content: center;
	}
	&.rounded-0{
		.page-item{
			.page-link{
				border-radius: 0;
			}
		}
	}	

	&.pagination-lg{
		li{
			.page-numbers,
			.page-link{
				height: 80px;
				min-width: 80px;
				line-height: 80px;
				border-radius: 80px;
				@include respond ('phone'){
					height: 50px;
					min-width: 50px;
					line-height: 50px;
					border-radius: 50px;
				}
			}
		}
	}
	&.pagination-sm{
		li{
			.page-numbers,
			.page-link{
				height: 40px;
				min-width: 40px;
				line-height: 40px;
				border-radius: 40px;
			}
		}
	}
}