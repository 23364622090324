/* Light Gallery */
.lg-actions .lg-next, 
.lg-actions .lg-prev, 
.lg-sub-html, 
.lg-toolbar{
    background-color: rgba(30, 30, 30, .6);
}
.lg-outer{
	.lg-toogle-thumb, 
	.lg-thumb-outer,
	.lg-img-wrap, 
	.lg-item{
		background-color: transparent;
	}
}
.lg-backdrop {
    background-color: rgba(30, 30, 30, 0.9);
}
.lg-outer .lg-toogle-thumb, 
.lg-actions .lg-next, 
.lg-actions .lg-prev, 
.lg-toolbar .lg-icon,
#lg-counter{
    color: $white;
}
.lg-outer{
	.lg-thumb-item{
		&.active,
		&:hover{
			border-color: var(--primary);			
		}
	}
}
.lightimg{
	cursor: pointer;
}



.SRLThumb{
	border: 2px solid #FFF;
	border-radius: 4px;
	margin-right: 5px;
	&.SRLThumbnailSelected{
		border-color: var(--primary);
		
	}
}