.icon-bx-wraper{
	// Style 1
	&.style-1{
		.icon-bx-sm{
			position:relative;
			z-index:1;
			&:after{
				content:"";
				position:absolute;
				height:calc(100% + 2px);
				width:calc(100% + 2px);
				background:var(--primary);
				border-radius:inherit;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%)scale(0);
				z-index:-1;
				@include transitionSlow;
			}
		}
		&:hover{
			.icon-bx-sm{
				&:after{
					transform:translate(-50%,-50%)scale(1);					
				}
				.text-primary{
					color:#fff!important;
				}
			}
		}
	}
	
	// Style 2
	&.style-2{
		display:flex;
		align-items:center;
		background:#f2f2f2;
		padding:35px 30px 35px 70px;
		margin-left:30px;
		z-index:1;
		.icon-bx-sm{
			position:absolute;
			top:50%;
			left:-30px;
			transform:translateY(-50%);
			@include transitionMedium;
		}
		&:before{
			content:"";
			position:absolute;
			top:0;
			left:50%;
			transform:translateX(-100%);
			clip-path: polygon(100% 0, 0 0, 100% 100%);
			width:35px;
			height:100%;
			background:#f8f8f8;
			z-index:-1;
			@include transitionMedium;
		}
		&:after{
			content:"";
			position:absolute;
			top:0;
			right:0;
			width:50%;
			height:100%;
			background:#f8f8f8;
			@include transitionMedium;
			z-index:-1;
		}
		&:hover{
			&:after{
				width:100%;
			}
			.icon-bx-sm{
				background-color: var(--secondary)!important;
			}
			&:before{
				width:0;
				left:0;
			}
		}
		@include respond ('phone'){
			padding:20px 20px 20px 60px;
		}
	}
	
	// Style 3
	&.style-3{
		padding:30px;
		background:#f1f1f1;
		@include transitionMedium;
		
		p,
		.dz-title,
		.icon-cell{
			@include transitionMedium;
		}
		
		&:hover{
			background-color: var(--primary);
			p,
			.dz-title,
			.icon-cell{
				color: #fff;
			}
		}
	}
	
	// Style 4
	&.style-4{
		[class*="icon-bx-"] {
			display: block;
			background-color: #000;
			text-align: center;
			outline: 2px solid var(--primary);
			outline-offset: 10px;
			margin: 12px 32px 12px 12px;
		}
		.icon-bx-sm img{
			height:40px;
		}
	}
	
	// Style 5
	&.style-5{
		margin-top:20px;
		[class*="icon-bx-"] {
			position:relative;	
			background: linear-gradient(90deg, var(--primary) 50%, transparent 50%), linear-gradient(90deg, var(--primary) 50%, transparent 50%), linear-gradient(0deg, var(--primary) 50%, transparent 50%), linear-gradient(0deg, var(--primary) 50%, transparent 50%);
			background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
			background-size: 8px 3px, 8px 3px, 3px 8px, 3px 8px;
			background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
			border-radius: 2px;
			&:after{
				content:attr(data-num);
				position:absolute;
				top:-20px;
				right:-20px;
				background:var(--primary);
				color:#fff;
				font-family:var(--font-family-title);
				text-align:center;
				height:46px;
				width:46px;
				line-height:46px;
				border-radius:46px;
			}
		}
		.icon-bx-sm{
			&:after{
				top:-10px;
				right:-10px;
				height:30px;
				width:30px;
				line-height:30px;
				font-size:16px;
			}
		}
		.icon-bx-lg img{
			height:70px;
		}
		&.center{
			[class*="icon-bx-"] {
				margin-bottom:20px;
			}
		}
		&:hover{
			[class*="icon-bx-"] {
				-webkit-animation: dash 5s linear infinite;
				animation: dash 5s linear infinite;
				img,
				i{
					animation: shake 0.5s;
					animation-iteration-count: infinite;
					display:inline-block;
				}
			}
		}
	}
	
	// Style 6
	&.style-6{
		padding:30px;
		background:#fff;
		border-radius:var(--border-radius-base);
		@include transitionSlow;
		&:hover,
		&.active{
			background:var(--secondary);
			.dz-title,
			.dz-title a,
			p{
				color:#fff;
			}
		}
	}
	
	// Style 7
	&.style-7{
		background:#fff;
		border:1px solid #737373;
		border-radius:var(--border-radius-base);
		padding:30px;
		@include transitionSlow;
		.dz-title,
		.dz-title a,
		p,
		a,
		.icon-cell{
			@include transitionSlow;
		}
		&:before{
			content:"";
			background:var(--secondary);
			height:100%;
			width:100%;
			top:0;
			left:0;
			border-radius:inherit;
			position:absolute;
			z-index:-1;
			@include transitionSlow;
		}
		&:hover{
			border-color:var(--primary);
			background:var(--primary);
			&:before{
				top:10px;
				left:10px;
			}
			.dz-title,
			.dz-title a,
			p,
			a,
			.icon-cell{
				color:#fff;
			}
		}
	}
	
	// Style 8
	&.style-8{
		[class*="icon-bx-"] {
			background:#f5f5f5;
			@include transitionMedium;
			margin-right: 15px;
			
			i{
				font-size: 25px;
				@include transitionMedium;
			}
		}
		.icon-content {
			padding-top: 5px;
		}
		p{
			font-family: var(--font-family-title);
		}
		&:hover{
			[class*="icon-bx-"] {
				background:var(--primary);
				i{
					color: #fff;
				}
			}
		}
	}
	
	// Style 9
	&.style-9{
		display:flex;
		align-items:center;
		[class*="icon-bx-"] {
			margin:0;
			margin-right:20px;
			position:relative;
			&:after{
				content:"";
				height:0;
				width:0;
				position:absolute;
				top:50%;
				left:50%;
				@include transitionMedium;
				background:var(--primary);
				z-index:-1;
				transform:translate(-50%,-50%);
			}
		}
		&:hover{
			[class*="icon-bx-"] {
				&:after{
					height:calc(100% + 2px);
					width:calc(100% + 2px);
				}
				a{
					color:#fff;
				}
			}
		}
	}
	
	
}

.flip-bx{
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	.inner{
		-webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
		transform: translateY(-50%) translateZ(60px) scale(0.94);
		top: 50%;
		position: absolute;
		left: 0;
		width: 100%;
		padding: 2rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 1px solid transparent;
		-webkit-perspective: inherit;
		perspective: inherit;
		z-index: 2;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.front,
	.back{
		background-size: cover;
		background-position: center;
		-webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
		transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
		-o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
		transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
		transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		text-align: center;
		min-height: 370px;
		height: auto;
		color: #fff;
	}
	.back{
		background:#212529;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg);
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		.inner{
			p{
				font-size:16px;
				color:#fff;
			}
			.site-button-link{
				font-size:16px;
			}
		}
	}
	.front{
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		&:after{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			content: '';
			display: block;
			opacity: .4;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			border-radius: 4px;
		}
	}
	&:hover{
		.back,
		.front{
			-webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			-o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
		}
		.back{
			-webkit-transform: rotateY(0deg);
			transform: rotateY(0deg);
			-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
		}
		.front{
			-webkit-transform: rotateY(-180deg);
			transform: rotateY(-180deg);
			-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
		}
	}	
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


@keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
}