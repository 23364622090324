.history-wrapper{	
	position:relative;
	.history-wrapper-bx{
		position:relative;
		display: flex;
		flex-flow: wrap;
		padding-top: 150px;
		padding-bottom: 70px;
		.year{
			height: 100px;
			width: 100px;
			line-height: 100px;
			border-radius: 100px;
			border: 2px solid rgba(0, 0, 0, .18);
			border-style: dashed;
			box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, .04), 0 0 0 16px rgba(0, 0, 0, .02);
			color: rgb(0, 0, 0);
			font-size: 26px;
			font-weight: 500;
			text-align: center;
			background:#fff;
			z-index:1;
			position:absolute;
			top:0;
			left:50%;
			transform:translateX(-50%);
		}
		.history-box{
			position:relative;
			width:50%;
			&:after{
				height:10px;
				width:10px;
				border-radius:10px;
				border:2px solid #000;
				content: "";
				position: absolute;
				background: #fff;
				z-index:1;
			}
			&:before{
				position:absolute;
				content:"";
				transform: rotate(45deg);
				border-width: 15px;
				border-style: solid;
			}
			.inner-content{
				background:#f5f5f5;
				padding: 30px 30px;
			}
			&:nth-child(even){
				padding-right:40px;
				padding-top:40px;
				text-align:right;
				&:before{
					right: 25px;
					top: 60px;
					border-color: #f5f5f5 #f5f5f5 transparent transparent;
				}
				&:after{
					right: -5px;
					top: 70px;
				}
			}
			&:nth-child(odd){
				padding-left:40px;
				padding-bottom:40px;
				&:before{
					left: 25px;
					top: 20px;
					border-color:transparent transparent #f5f5f5 #f5f5f5;
				}
				&:after{
					left: -5px;
					top: 30px;
				}
			}
		}
	}
	&:after{
		content:"";
		position:absolute;
		height:100%;
		width:2px;
		background:#000;
		bottom:0;
		left:50%;
		transform:translateX(-50%);
	}
	@include respond ('phone-land'){
		.history-wrapper-bx{
			padding-left: 35px;
			padding-top:100px;
			padding-bottom:0;
			.history-box{
				padding-left:40px;
				padding-bottom:40px;
				&:nth-child(even){
					padding-right:0;
					padding-top:0;
					text-align:left;
					&:before{
						right: auto;
						left:25px;
						top: 50px;
						border-color:transparent transparent #f5f5f5 #f5f5f5;
					}
					&:after{
						right: auto;
						left: -5px;
						top: 60px;
					}
				}
				&:nth-child(odd){
					padding-left:40px;
					padding-bottom:40px;
					&:before{
						left: 25px;
						top: 50px;
						border-color:transparent transparent #f5f5f5 #f5f5f5;
					}
					&:after{
						left: -5px;
						top: 60px;
					}
				}
			}
			.year{
				height: 70px;
				width: 70px;
				line-height: 70px;
				border-radius: 70px;
				font-size: 18px;
				left:0;
				transform:translateX(0px);
			}
			.history-box{
				width:100%;
			}
		}
		&:after{
			left:35px;
			transform:translateX(0px);
		}
	}
	@include respond ('phone'){
		.history-wrapper-bx{
			padding-left:0;
			.year{
				left:50%;
				transform:translateX(-50%);
			}
		}
		&:after{
			left:0;
			height:calc(100% - 160px);
		}
	}
}
